import {
    useHistory
} from 'react-router-dom'
import filter from 'lodash/filter';
import includes from 'lodash/includes';

export const ChangeLocation = (location) => {
    let history = useHistory();
    history.push(location);
};

export const FilterCollection =(collection,incl,by)=>{
    let output = filter(collection, (v) => includes(incl, v[by]));
    return output
}

export const NameCuter =(name)=> {
    let newName = ''
    if (name.length > 13) {
        return newName = name.substring(0, 13) + '...'
    }
    return name
}

export const GetOcupation =(text,position)=> {

    return text.split('at')[position]
}

export const TextCuter =(name, length)=> {
    let newName = '';
    if (name.length > length) {
        return newName = name.substring(0, length) + '...'
    }
    return name
}
