export const GetSheduleList = async function(){
    const response = await fetch('/data/campaignsschedule.json');
    const payload = await response.json();
    if(payload.status.code === 0){

        return payload.data


    }else{
        throw new Error("failed!");

    }

};
export const GetTemplateList = async function(){
    const response = await fetch('/data/templatesarchived.json');
    const payload = await response.json();
    if(payload.status.code === 0){
        return payload.data
    }else{
        throw new Error("failed!");
    }

};

export const GetAccountsList = async function(){
    try {
        const response = await fetch('/accounts/',{
            method: 'GET',
            credentials: 'include',
        });
        const payload = await response.json();
        if(payload.status.code === 0){
            if(payload.data.length>0){
                let transformedArrey = Array.from(payload.data, (item) => {
                    return {id:item.id,name:`${item.firstName} ${item.lastName}`};
                })
                return transformedArrey;
            }
        }else{
            throw new Error("failed!");
        }
    }catch (error){
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }

};

export const CreateCampaign = async function(data){
    try {
        const response = await fetch('create_campaign', {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const payload = await response.json();
        if(payload.status.code === 0){
            return true
        }else{
            throw new Error("failed!");
        }
    } catch (error) {
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }
};

export const SubmitNewAccountCreds = async function(data){
    try {
        const response = await fetch('/accounts/add', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const payload = await response.json();
        if(payload.status.code === 0){
            if(Object.keys(payload.data.pinData).length>0){
                return {pinData:payload.data.pinData,uuid:payload.data.uuid}
            }else{
                return {finish:true};
            }

        }else{
            throw new Error("failed!");
        }
    } catch (error) {
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }
};
export const SubmitCode = async function(data){
    try {
        const response = await fetch(`/accounts/${data.uuid}/pin_verify`, {
            method: 'POST',
            body: JSON.stringify({
                "code": data.code,
                "pinData": data.pinData
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const payload = await response.json();
        if(payload.status === 0){
            return true
        }else{
            if(payload.status === 1) {
                return false
            }else{
                throw new Error("failed!");
            }
        }
    } catch (error) {
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }
};

export const GetConnections = async function(){
    try {
        const response = await fetch('/connections/5e01572e-064c-411e-881d-94b0b52c3e52/outgoing',{
            method: 'GET',
            credentials: 'include',
        });
        const payload = await response.json();
        if(payload.status.code === 0){
            return payload.data
        }else{
            throw new Error("failed!");
        }
    }catch (error){
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }
}

export const GetUserMe = async function(){
    try {
        const response = await fetch('/user/me',{
            method: 'GET',
            credentials: 'include',
        });
        const payload = await response.json();
        if(payload.status === 0){
            return payload.data
        }else{
            throw new Error("failed!");
        }
    }catch (error){
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }
}

export const LogoutQuery = async function(){
    try {
        const response = await fetch('/auth/logout',{
            method: 'POST',
            credentials: 'include',
        });
        const payload = await response.json();
        if(payload.status === 0){
            return true
        }else{
            throw new Error("failed!");
        }
    }catch (error){
        console.error('Ошибка:', error);
        throw new Error("failed!");
    }
}
