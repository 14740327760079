import React, { useEffect } from 'react'
import styles from "./spinner.module.css"

function Spinner(){


    return (
        <div className={styles.wrapper}>
            <div className={styles.lds_dual_ring}></div>
        </div>
    )
}


export default Spinner
