import React, {useState} from 'react'
import styles from './new_schedule.module.css'
import moment from "moment";
import StartDate from '../input/data_start/date_start'
import EndDate from '../input/date_end/date_end'
import StartTime from '../input/tyme/clock'
import EndTime from '../input/tyme/clock'
import Header from '../input/input_header/input_header'
import Input from '../input/input_text/input_text'

import { ReactComponent as LeftArrow } from "./Arrow-Left.svg"
import { ReactComponent as PenActive } from "./EditActive.svg"
import { ReactComponent as Pen } from "./Edit.svg"
import Button from '../regular_button/regular_button'
import Box from '../input/checkbox/checkbox'






function NewSchedule(props){
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
      });
    const [selectedTymeRange, setSelectedTymeRange] = useState({
        from: {hour:'',min:'',ampm:''},
        to:  {hour:'',min:'',ampm:''}
      });
    const [scheduleName, setscheduleName] = useState('Schedule Name');
    const [interval, setIntervalinsec] = useState('');
    const [description, setDescription] = useState('');
    const days=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    const [allDays, setAllDays] = useState(false);

    const setAllDaysWrap = ()=>{
        setAllDays(!allDays);
    }


    return (
        <div className={styles.wrapper}>

            <div className={styles.backbutton}>
                <LeftArrow/>
                <span >Back</span>

            </div>
            <div className={styles.header_wrapper}>
                <div className={styles.header_input_wrapper}>
                    <Header
                        type={'text'}
                        name={'header'}
                        value={scheduleName}
                        onchange={setscheduleName}
                        activeIcon={<PenActive/>}
                        notActiveIcon={<Pen/>}


                    />
                </div>
                <div className={styles.save_button}>
                    <Button name={'Create'} click={()=>{console.log('save')}}
                            baground={'#5276F4'}
                            color={'#F8F9FB'}
                            padding={'13px 30px'}
                            fontSize={'14px'}
                            lineHeight={'21px'}


                    />
                </div>

            </div>
            <div className={styles.field_wrapper}>
                <div className={styles.section}>
                    <div className={styles.subsection}>
                        <span className={styles.section_name}>Main information</span>
                        <div className={styles.field_sub_wrapper}>
                            <div className={styles.start_day}>
                                <StartDate
                                    value={selectedDayRange.from?selectedDayRange.from:null}
                                    setValue={(data)=>{setSelectedDayRange(prevState => {
                                        return Object.assign({}, prevState, {from:data});
                                      })}}
                                />
                            </div>
                            <div className={styles.start_day}>
                                <EndDate
                                    value={selectedDayRange}
                                    setValue={(data)=>{
                                         setSelectedDayRange(prevState => {return Object.assign({}, prevState, {to:data.to});});
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.field_sub_wrapper}>
                            <div className={styles.start_day}>
                                <StartTime
                                    placeholder='From time'
                                    value={selectedTymeRange.from}
                                    setValue={(data)=>{
                                         setSelectedTymeRange(prevState => {return Object.assign({}, prevState, {from:data});});
                                    }}
                                />
                            </div>
                            <div className={styles.start_day}>
                                <EndTime
                                    placeholder='Till time'
                                    value={selectedTymeRange.to}
                                    setValue={(data)=>{
                                        console.log(data);
                                         setSelectedTymeRange(prevState => {return Object.assign({}, prevState, {to:data});});
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`${styles.field_sub_wrapper} ${styles.short}`}>
                            <Input
                                type={'number'}
                                name={'interval'}
                                value={interval}
                                onchange={setIntervalinsec}
                                placeholder={'Interval (in sec)'}
                            />
                        </div>
                    </div>
                    <div className={styles.subsection2}>
                        <span className={styles.section_name}>Days</span>
                        <div className={styles.days_wrapper}>
                            {days.map((item, index)=>{
                                return <div key={index}><Button  name={item} click={()=>{console.log(item)}}
                                     baground={'none'}
                                     color={'#4B4D72'}
                                     padding={'10px 16px'}
                                     fontSize={'14px'}
                                     lineHeight={'21px'}
                                /></div>
                            })}
                        </div>
                        <div>
                            <Box placeholder={'Select all days'} value={allDays} click={()=>{setAllDaysWrap()}}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles.section} ${styles.right}`}>
                    <div className={styles.subsectionrigh}>
                        <span className={styles.section_name}>Additional</span>
                        <div className={styles.big_input_wrapper}>
                            <Input
                                type={'textarea'}
                                name={'description'}
                                value={description}
                                onchange={setDescription}
                                placeholder={'Description'}
                                rows={12}
                                cols={50}
                                paddingTop={'30px'}

                            />
                        </div>
                    </div>
                </div>
            </div>





        </div>
    )
}


export default NewSchedule
