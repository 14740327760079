import React, {useState, useEffect} from 'react';
import styles from './sched_card.module.css';
import { ReactComponent as Kebab } from "./kebab.svg";


function SchedCard(props){
    const [menuOpen, setMenuOpen] = useState(false);




    const campaignAction = (id,param)=>{
        console.log(id, param);
    }


    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper_content}>
                <span className={styles.name}>{props.name}</span>
                <span className={styles.description}>{props.description}</span>
                <span className={styles.template}><span className={styles.icon}>Period:</span><span>{props.date.start +'-'+props.date.end}</span></span>
                <span className={styles.template}><span className={styles.icon}>Days:</span><span>{props.days}</span></span>
                <div className={styles.kebub_wrapper} onClick={()=>{setMenuOpen(true)}}><Kebab/></div>
            </div>

            {menuOpen?<div className={styles.menu_wrappe} onClick={()=>{setMenuOpen(false)}}>
                <div className={styles.menu_list}>
                    <span onClick={()=>{campaignAction(props.id,'edit')}}>Edit</span>
                    <span onClick={()=>{campaignAction(props.id,'delete')}}>Delete</span>
                </div>
            </div>:''}




        </div>
    )
}


export default SchedCard
