import React, {useState, useEffect} from 'react';
import styles from './camp_card.module.css';
import find from 'lodash/find';
import { ReactComponent as Kebab } from "./kebab.svg";
import { ReactComponent as Template } from "./Vector.svg";
import { ReactComponent as Date } from "./Vector2.svg";
import Button from '../regular_button/regular_button';


function CampCard(props){
    const [menuOpen, setMenuOpen] = useState(false);
    const stat={
        0:{color:'#7E86A5',
            bagroundColor:'#EEF0F6',
            name:'Finished'
        },
        1:{color:'#F17155',
            bagroundColor:'#FCE0D9',
            name:'Stoped'
        },
        2:{color:'#F2A32C',
            bagroundColor:'#FCE8CA',
            name:'Paused'
        },
        3:{color:'#85D322',
            bagroundColor:'#EDFDD8',
            name:'Running'
        }
    }

    const getFAces = (id,index)=>{
       return <div key={index} className={styles.round} style={{left:index*20}}><img src={find(props.accounts,(o)=>{
           return o.id === id
       })['img']}></img></div>
    }
    const getNumber = (index)=>{
        return <div key={index} className={styles.round} style={{left:index*20}}><span>{'+'+props.accountsId.length}</span></div>
    }
    const getParam = (status,param)=>{
        return stat[status][param]
    }
    const campaignAction = (id,param)=>{
        console.log(id, param);
    }


    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper_content}>
                <span className={styles.name}>{props.name}</span>
                <span className={styles.description}>{props.description}</span>
                <span className={styles.template}><span className={styles.icon}><Template/></span><span>{props.template}</span></span>
                <span className={styles.template}><span className={styles.icon}><Date/></span><span>{props.date.start+' - '+props.date.end}</span></span>
                <div className={styles.kebub_wrapper} onClick={()=>{setMenuOpen(true)}}><Kebab/></div>
            </div>
            <div className={styles.wrapper_faces_buton}>
                <div className={styles.faces}>
                    {props.accountsId.map((item,index)=>{
                        if(index<2){
                            return getFAces(item,index)
                        }
                        if(index===2){
                            return getNumber(index)
                        }
                    })}
                </div>
                <div className={styles.button}>
                    <Button name={getParam(props.status,'name')} click={()=>{console.log('cklik')}}
                            baground={getParam(props.status,'bagroundColor')}
                            color={getParam(props.status,'color')}
                            padding={'6px 20px'}
                            fontSize={'12px'}
                            lineHeight={'18px'}
                            border={'none'}
                    />
                </div>
            </div>
            {menuOpen?<div className={styles.menu_wrappe} onClick={()=>{setMenuOpen(false)}}>
                <div className={styles.menu_list}>
                    <span onClick={()=>{campaignAction(props.id,'pause')}}>Pause</span>
                    <span onClick={()=>{campaignAction(props.id,'stop')}}>Stop</span>
                    <span onClick={()=>{campaignAction(props.id,'edit')}}>Edit</span>
                    <span onClick={()=>{campaignAction(props.id,'delete')}}>Delete</span>

                </div>
            </div>:''}




        </div>
    )
}


export default CampCard
