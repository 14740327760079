import React, {useEffect} from 'react';
import styles from './templates_archived.module.css';

import {
    useHistory
} from 'react-router-dom'






import {useDispatch, useSelector} from "react-redux";
import {fetchTemplatesArchived,changeTime,changeSearchString,changeDate} from "./TemplatesArchivedSlice";



import NoData from    '../../components/no_data/no_data'
import RegButtonLinck from '../../components/regular_button_link/regular_button_link'
import Search from "../../components/input/search/search";
import TemplateCard from "../../components/template_card/template_card";




function TemplatesArchived () {
    let history = useHistory();


    const dispatch = useDispatch()
    const postStatus = useSelector(state => state.templatesArchived.status);
    const error=useSelector(state=>state.templatesArchived.error);
    const templates=useSelector(state=>state.templatesArchived.templates);
    const search=useSelector(state=>state.templatesArchived.search);


    useEffect(() => {
        if (postStatus === 'idle') {
            dispatch(fetchTemplatesArchived())
        }
    }, []);

    const changeLocation=(location)=>{
        history.push(location);
    }




    const getContent=()=>{
        if(templates.length>0){
            return <div className={styles.wrapper}>

                <div className={styles.tools}>
                    <div className={styles.search_wrapper}>
                        <Search
                            type={'text'}
                            name={'search'}
                            value={search.string}
                            onchange={(data)=>{
                                dispatch(changeSearchString(data))
                            }}
                            radius={'8px'}
                        />
                    </div>

                    <div className={styles.button_wrapper2}>
                        <RegButtonLinck
                            name={'+ New template'}
                            color={'#F8F9FB'}
                            baground={'#5276F4'}
                            click={()=>{changeLocation('/app/templates/new_templates')}}

                        />
                    </div>
                </div>

                <div className={styles.subwrapper}>
                    {templates.map((item,index)=>{
                        return <div key={index} className={styles.card_wrapper}><TemplateCard
                            name={item.name}
                            description={item.description}
                            id={item.id}
                        /></div>
                    })}
                </div>

            </div>


        }else{
            return <NoData
                header={'There are no templates yet'}
                subheader={'Please, click below to create the first one.'}
                width={341}
            >
                <div className={styles.button_wrapper}>
                    <RegButtonLinck
                        name={'+ New template'}
                        color={'#F8F9FB'}
                        baground={'#5276F4'}
                        click={()=>{changeLocation('/app/templates/new_templates')}}
                    />
                </div>
            </NoData>
        }
    }







    return(
        getContent()
    )
}

export default TemplatesArchived;
